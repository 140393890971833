function ErrorMessage({
  message,
  className,
}: {
  message: string;
  className?: string;
}) {
  if (!message) return null;
  return <small className={`text-danger ${className}`}>{message}</small>;
}
export default ErrorMessage;
