import styled from 'styled-components';
import GenericCard from 'components/ui/GenericCard';
import { Badge, Form } from 'react-bootstrap';

export const Wrapper = styled.div`
  .order-number {
    color: #0d6efd !important;
  }
`;

export const ProductCard = styled(GenericCard)`
  .gcard-body {
    display: flex;
    justify-content: space-between;
  }
  .sc--prodinfo {
    h3,
    .h3 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.5;
    }
  }
  .sc--action {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .field-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ListingCard = styled(GenericCard)``;
export const CashoutCard = styled(GenericCard)``;
export const OrdersCard = styled(GenericCard)`
  .gcard-body {
    padding: 0;
  }
`;
export const FilterTabs = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 0.1rem;
  justify-content: space-between;
  flex-wrap: wrap;
  /* TODO: Refactor color */
  background: #ececec;
  padding: 0.25rem;
  border-radius: 2rem;
  li > a {
    display: block;
    padding: 0.5rem 0.75rem;
    color: ${(props) => props.theme.colors.black};
    border-radius: 2rem;
    &.active {
      background: ${(props) => props.theme.colors.white};
    }
  }
`;

export const ListingItem = styled.div`
  margin-bottom: 1rem;
  .prod--img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  .prod--info {
    gap: 0.7rem;
    h4 {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
    }
  }
  .prod--date {
    font-size: 0.875rem;
    color: ${(props) => props.theme.colors.black};
    opacity: 0.6;
  }
`;

export const CashoutItem = styled.div`
  margin-bottom: 1.5rem;
  .cashout--amt {
    /* Todo: color refactor */
    font-size: 1rem;
    color: #e56262;
    font-weight: 700;
  }
  .cashout--date {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.black};
  }
`;
export const BankAcCard = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 0.75rem;
  padding: 1.25rem;
  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
  .bank-ac--info {
  }
  .bank-ac--action {
  }
  .bank-ac--name {
    font-size: 0.9375rem;
    font-weight: 700;
  }
  .bank-ac--cardNo {
    font-size: 1.125rem;
    line-height: 1.5;
  }
  .bank-ac--bankName {
    font-size: 0.9375rem;
    img {
      margin-right: 5px;
    }
  }
`;

export const CustomUploader = styled.label<{ disabled?: boolean }>`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.75 : 1)};
  input[type='file'] {
    display: none;
  }
`;
CustomUploader.defaultProps = {
  disabled: false,
};

export const UploadPreviewImage = styled.div`
  position: relative;
  width: 95px;
  height: 95px;
  border-radius: 8px;
  border: 1px solid #ededed;
  background-color: #f5f5f5;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .del-img {
    width: 26px;
    height: 26px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    opacity: 1;
    font-weight: 400;
    color: black !important;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125em;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.17);
    transform: translate(50%, -50%);
  }
`;

export const ProfileForm = styled(Form)`
  .form-label {
    color: #292d32;
    font-size: 14px;
  }
  .form-control {
    min-height: 51px;
    padding-left: 1.25rem;
    border: 1px solid #e7e7e7;
    border-radius: 0.5rem;
  }
  .uploadedImages {
    gap: 1.125rem;
  }
`;

export const UploadPlaceholder = styled.div`
  width: 95px;
  height: 95px;
  border-radius: 8px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const StyledSizeBadge = styled(Badge)`
  font-size: 0.875rem;
  color: black;
  background-color: #f1f1f1 !important;
  padding: 0.5rem 1.125rem;
  border-radius: 1rem;
  line-height: 1.313rem;
`;

export const ActionFooter = styled.div`
  background-color: white;
  box-shadow: 0px -13px 34px rgba(0, 0, 0, 0.05);
  border-radius: 12px 12px 0px 0px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 50px);
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  button.btn {
    height: 51px;
    padding: 0 2rem;
  }
`;

export const ProductFieldView = styled.div`
  .product-field {
    margin-bottom: 1rem;
  }
  .product-field-title {
    font-size: 1.125rem;
  }
  .field-input {
    width: 8rem;
  }
  .field-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .product-other-issue-text {
    color: ${({ theme }) => theme.colors.black};
  }
  .issue-textarea {
    min-width: 50%;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    width: 100%;
    padding: 10px 20px;
  }
  margin-bottom: 1rem;
`;

export const QRCodeView = styled.div`
  position: relative;
  padding: 1rem;
  border: 1px solid #eaeaea;
  border-radius: 1rem;
  box-shadow: 0 0 5px #ebebeb;
  .qr-product-name {
    margin-bottom: 0.5rem;
  }
  .sku {
    padding: 0.5rem 0.875rem;
    padding-top: 0;
    padding-left: 0;
  }
  .qr-tag {
    background-color: ${({ theme }) => theme.colors.gray4};
    margin-bottom: 0.625rem;
    width: fit-content;
    padding: 0.5rem 0.875rem;
    border-radius: 6px;
  }
  .qr-logo {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
`;
