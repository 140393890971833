import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import { getBrands } from 'helpers/http/common';
import { getProductTypes } from 'helpers/http/product';

const genderOptions = [
  { value: 'men', label: 'Men' },
  { value: 'women', label: 'Women' },
  { value: 'kid', label: 'Kid' },
];

const getOptions = (data: any[], label: string, value: string) => {
  return data.map((item: any) => {
    return { label: item[label], value: item[value] };
  });
};

type Props = {
  show: boolean;
  onFilter: (value: any) => void;
  onClose?: () => void;
};

export const StyledModal = styled(Modal)`
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    color: white;
    transform: translate(30px, -10px);
    font-size: 1.75rem;
    font-weight: 200;
  }
  .modal-content {
    border-radius: 1.25rem;
  }
  .modal-title {
    color: ${(props) => props.theme.colors.darkText};
    font-weight: 700;
  }
  .modal-body {
    padding: 0rem 2.75rem 2.75rem 2.75rem;
  }
  .modal-header {
    padding: 2.75rem;
    border-bottom: 0;
  }
`;

const CancelButton = styled(Button)`
  background-color: #f3f3f3 !important;
  border: none;
  color: #000 !important;
  padding: 1rem 2rem;
`;

const ApplyButton = styled(Button)`
  padding: 1rem 2rem;
`;

const OptionView = styled.div`
  label {
    color: #292d32;
    font-weight: 400;
  }
`;

const CheckboxInput = styled.input`
  width: 24px;
  height: 24px;
`;

const SelectView = styled(Select)`
  .react-select__control {
    border: 1px solid #e7e7e7;
    .react-select__indicators {
      div {
      }
      display: none;
    }
  }
  .react-select__multiValue {
    background-color: #f0f0f0;
    border-radius: 4px;
    height: 32px;
    align-items: center;
  }
`;

const customStyles = {
  option: (provided: any) => ({
    ...provided,
    backgroundColor: 'white !important',
  }),
  control: (provided: any) => ({
    ...provided,
    border: '1px solid #E7E7E7 !important',
    height: 52,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    '& svg': {
      fill: '#000 !important',
    },
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#F0F0F0 !important',
    height: 32,
    alignItems: 'center',
    borderRadius: 4,
    '& > div': {
      fontSize: 13,
      backgroundColor: '#F0F0F0 !important',
    },
    '& svg': {
      width: 16,
      height: 32,
      fill: '#000 !important',
    },
  }),
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <OptionView className="d-flex align-items-center gap-2">
          <CheckboxInput
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label className="fs-1rem">{props.label}</label>
        </OptionView>
      </components.Option>
    </div>
  );
};

const DEFAULT_FILTER: {
  type: any;
  brand: any;
  gender: { label: string; value: string }[];
} = {
  type: '',
  brand: '',
  gender: [],
};

function FilterPopup({ show, onFilter, onClose }: Props) {
  const [filters, setFilters] = useState<typeof DEFAULT_FILTER>(DEFAULT_FILTER);
  const [types, setTypes] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);

  const handleApply = useCallback(() => {
    const filterData = {
      categoryId: filters.type ? [filters.type?.value] : [],
      brandId: filters.brand ? [filters.brand?.value] : [],
      gender: filters.gender.map((item: any) => item.value),
    };
    onFilter(filterData);
  }, [filters, onFilter]);

  useEffect(() => {
    Promise.all([getProductTypes(), getBrands()]).then(([typeData, brandData]) => {
      setTypes(getOptions(typeData.data, 'category_name', 'category_id'));
      setBrands(getOptions(brandData.data, 'brand_name', 'brand_id'));
    });
  }, []);

  return (
    <StyledModal show={show} centered>
      <Modal.Header>
        <Button variant="transparent" className="close" onClick={onClose}>
          &times;
        </Button>
        <Modal.Title>Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2r">
          <div className="fs-1rem fw-700 mb-2">Type</div>
          <SelectView
            hideSelectedOptions={true}
            options={types}
            isClearable={true}
            styles={customStyles}
            placeholder="Choose..."
            value={filters.type}
            onChange={(val) =>
              setFilters((values: any) => {
                return { ...values, type: val };
              })
            }
          />
        </div>

        <div className="mb-2r">
          <div className="fs-1rem fw-700 mb-2">Brands</div>
          <SelectView
            options={brands}
            hideSelectedOptions={true}
            isClearable={true}
            styles={customStyles}
            value={filters.brand}
            placeholder="Choose..."
            onChange={(val) =>
              setFilters((values: any) => {
                return { ...values, brand: val };
              })
            }
          />
        </div>
        <div className="mb-2r">
          <div className="fs-1rem fw-700 mb-2">Gender</div>
          <SelectView
            closeMenuOnSelect={false}
            isMulti
            placeholder="Choose..."
            options={genderOptions}
            components={{
              Option,
            }}
            isClearable={false}
            styles={customStyles}
            hideSelectedOptions={false}
            value={filters.gender}
            onChange={(val) =>
              setFilters((values: any) => {
                return { ...values, gender: val };
              })
            }
          />
        </div>
        <div className="d-flex justify-content-end gap-3">
          <CancelButton variant="dark" onClick={onClose}>
            Cancel
          </CancelButton>
          <ApplyButton variant="dark" onClick={handleApply}>
            Apply
          </ApplyButton>
        </div>
      </Modal.Body>
    </StyledModal>
  );
}

export default FilterPopup;
