import { useMediaQuery } from 'react-responsive';
function useResponsive() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const isIpadPro = useMediaQuery({ minWidth: 991, maxWidth: 1250 });
  return {
    isDesktop,
    isTablet,
    isMobile,
    isNotMobile,
    isIpadPro,
  };
}

export const mediaQuery = {
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
};

export default useResponsive;
