import React from 'react';

import {
  Button,
  Image,
  NavLink,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from 'helpers/contexts/auth-context';

import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as Tag } from 'assets/icons/tag.svg';
import { ReactComponent as List } from 'assets/icons/listing.svg';
import { ReactComponent as Seller } from 'assets/icons/shop.svg';
import { ReactComponent as Bag } from 'assets/icons/bag.svg';
import { ReactComponent as Collections } from 'assets/icons/collections.svg';
import { ReactComponent as Transactions } from 'assets/icons/transactions.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as ShoppingCard } from 'assets/icons/shopping-cart.svg';
import { ReactComponent as Support } from 'assets/icons/message.svg';
import { ReactComponent as Discount } from 'assets/icons/discount.svg';
import { ReactComponent as PullItems } from 'assets/icons/pull-items.svg';
import { useUtil } from 'helpers/contexts/util-context';

const SidebarWrapper = styled.div`
  .minimizeSidebar {
    width: var(--minimizeNavWidth) !important;
    .toggle {
      transform: rotate(180deg);
      background-color: transparent !important;
    }
  }
  .sidenav {
    margin: 10px;
    border-radius: 1rem;
    background: black;
    color: white;
    transition: all 0.2s ease;
    width: 250px;
    position: fixed;
    left: 0;
    top: 0;
    height: calc(100% - 20px);
    z-index: 9;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 2.4rem;
    overflow: scroll;
    /* svg {
      fill: currentColor;
      width: 30px;
      height: 20px;
    } */
  }
  .searchMini {
    color: var(--textTinted);
    background-color: var(--searchBg) !important;
  }
  .search {
    position: relative;
    color: var(--textTinted);
    input {
      background-color: var(--searchBg);
      color: var(--text);
      border-radius: 4px;
      outline: 0;
      padding: 0.525rem 0.745rem;
      padding-left: 2.4rem;
      border: 0;
      width: 100%;
    }
    svg {
      position: absolute;
      left: 10px;
      top: 8px;
      width: 22px;
      height: 22px;
    }
  }

  .sidenavInner {
    padding-left: 0;
    list-style: none;
    flex: 1;
    li > a {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0.6rem;
      border-radius: 4px;
      margin-bottom: 1rem;
      color: #c1c9d2;
      svg {
        width: 1.25rem;
        height: 1.25rem;
        stroke: #c1c9d2;
        margin-right: 0.8rem;
        path {
          stroke: #c1c9d2;
        }
      }

      &.active,
      &:hover {
        background-color: #e7e7e7;
        color: #292d32;
        svg {
          stroke: #292d32;
          path {
            stroke: #292d32;
          }
        }
      }
      &.minimized {
        svg {
          margin-right: 0;
        }
      }
      .icon {
        margin-right: 10px;
      }
    }
  }
`;
const BackButton = styled(Button)`
  width: 38px;
  height: 38px;
  background-color: #3e3e3e;
  border: none;
  box-shadow: 0;
  outline: 0;
  position: absolute;
  right: 0;
  top: 30px;
  border-radius: 8px 0px 0px 8px;
  &:hover,
  &:focus {
    background-color: #3e3e3e;
  }
`;

const LINKS = [
  { title: 'Dashboard', toLink: '/dashboard', icon: <Dashboard /> },
  {
    title: 'Orders',
    toLink: '/orders',
    icon: <ShoppingCard />,
    subLinks: ['/order'],
  },
  { title: 'Products', toLink: '/products', icon: <Tag /> },
  { title: 'Listings', toLink: '/listing', icon: <List />, subLinks: ['/qr'] },
  { title: 'Pull Items', toLink: '/pull-items', icon: <PullItems />, subLinks: ['/pull-item'] },
  {
    title: 'Sellers',
    toLink: '/sellers',
    icon: <Seller />,
    subLinks: ['/seller'],
  },
  { title: 'Buyers', toLink: '/buyers', icon: <Bag />, subLinks: ['/buyer'] },
  { title: 'Collections', toLink: '/collections', icon: <Collections /> },
  { title: 'Transactions', toLink: '/transactions', icon: <Transactions /> },
  { title: 'Support', toLink: '/support', icon: <Support /> },
  { title: 'Discount coupons', toLink: '/discounts', icon: <Discount /> },
  // { title: 'Admins', toLink: '/admins', icon: <Admin /> },
  // { title: 'Settings', toLink: '/settings', icon: <Setting /> },
];

const getActive = (path: string, link: typeof LINKS[0]) => {
  if (path.includes(link.toLink)) {
    return true;
  }
  if (link.subLinks) {
    return link.subLinks.find((item) => path.includes(item)) !== undefined;
  }
  return false;
};

function SideBar({ show, toggle }: any) {
  const { signout, user } = useAuth();
  const { resetSearchKeyword } = useUtil();
  return (
    <SidebarWrapper>
      <aside
        className={'sidenav  p-3'}
        style={{
          width: show ? '250px' : '76px',
        }}
        onClick={() => resetSearchKeyword()}
      >
        <div className="app-brand d-flex justify-content-between align-items-center mb-4 mt-1">
          {show ? (
            <NavLink>
              <Image src="/logo-white.png" width="78px" fluid />
            </NavLink>
          ) : (
            <div style={{ paddingTop: 70 }} />
          )}

          <BackButton onClick={toggle}>
            <span
              className="d-block"
              style={{
                transform: !show ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            >
              <Chevron />
            </span>
          </BackButton>
        </div>

        <ul className={'sidenavInner'}>
          {LINKS.map((alink) => (
            <CustomNavLink
              {...alink}
              key={alink.title}
              isMinimized={!show}
              active={getActive(window.location.pathname, alink)}
            />
          ))}
        </ul>

        <div
          className={`${'footer'} d-flex align-items-center justify-content-between`}
        >
          {show && (
            <div className="d-flex">
              <div>
                <Image src="/images/default_avatar.png" width="44px" />
              </div>
              <div className="flex-1 ms-2">
                <h6 className="mb-0">{user.name}</h6>
                <span className="text-muted">Admin</span>
              </div>
            </div>
          )}

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-logout`}>Log out</Tooltip>}
          >
            <Button title="Logout" variant="link" onClick={signout}>
              <Logout stroke="white" />
            </Button>
          </OverlayTrigger>
        </div>
      </aside>
    </SidebarWrapper>
  );
}

export default SideBar;

function CustomNavLink(props: {
  title: string;
  icon?: React.ReactNode;
  active: boolean;
  isMinimized: boolean;
  toLink: string;
  disable?: boolean;
}) {
  return (
    <li key={props.title}>
      <NavLink
        disabled={props.disable}
        as={Link}
        to={props.toLink}
        className={`${'link'} ${
          props.active && !props.disable ? 'active' : ''
        } ${props.isMinimized ? 'minimized' : ''}`}
      >
        {props.icon}
        {!props.isMinimized && <span>{props.title}</span>}
      </NavLink>
    </li>
  );
}
