import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    color: white;
    transform: translate(30px, -10px);
    font-size: 1.75rem;
    font-weight: 200;
  }
  .modal-content {
    border-radius: 1.25rem;
  }
  .modal-title {
    color: ${(props) => props.theme.colors.darkText};
    font-weight: 700;
  }
  .modal-body {
    padding: 1rem 2rem;
  }
  .modal-header {
    padding: 2rem 2rem 0.5rem 2rem;
    border-bottom: 0;
  }
  /* .modal--head {
    margin-top: 20px;
  } */
  .modal--title {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.darkText};
  }
  .modal--subtitle {
    font-weight: 500;
    color: ${(props) => props.theme.colors.darkText};
  }
  .modal--foot{
    
  }
  .qr-modal-content {
    padding: 1rem;
    .modal--title {
      margin-left: 0.5rem;
    }
  }
`;
