import { apiClient } from './index';

export const getDefaultProducts = ({ queryKey }: any) => {
  const [, filters, keyword, page, limit] = queryKey;
  const data = { ...filters };
  let url = `/admin/products?page=${page}&limit=${limit}`;
  if (keyword) {
    url = `${url}&keyword=${keyword}`;
  }
  return apiClient.post(url, data).then((res) => res.data);
};

export const addDefaultProduct = (data: any) => {
  return apiClient.post('/admin/product/add', data).then((res) => {
    if (res.data.status) {
      return res.data;
    } else {
      throw new Error(res.data.message);
    }
  });
};
export const updateDefaultProduct = (data: any) => {
  return apiClient
    .post(`/admin/product/${data.product_catalog_id}/update`, data)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
};

export const getTypes = () => {
  return apiClient
    .post(`/admin/product-types`, {
      action: 'list_type',
    })
    .then((res) => res.data);
};
export const addType = (name: string) => {
  return apiClient
    .post(`/admin/product-types`, {
      action: 'add_type',
      category_name: name,
    })
    .then((res) => res.data);
};

export const deleteProduct = (id: string) => {
  return apiClient
    .delete(`/admin/delete/product/${id}`)
    .then((res) => res.data);
};

export const getProductConfig = (category_id: number) => {
  return apiClient
    .get(`/general/category/${category_id}/config`)
    .then((res) => res.data);
};
// new
export const getDefaultListing = (keyword?: string, filters?: any) => {
  return apiClient
    .post(`/admin/products?page=1&limit=1000&keyword=${keyword || ''}`, {
      ...filters,
    })
    .then((res) => res.data);
};
export const getProductTypes = () => {
  return apiClient.get(`/product/types`).then((res) => res.data);
};
