import toast from 'react-hot-toast';
import moment from 'moment';

export const getImageUrl = (data: string, type: 'single' | 'multi'): string => {
  const parsed = JSON.parse(data);
  if (type === 'single') {
    return parsed[0];
  }
  return parsed;
};

export const showMsg = (msg: string) => {
  toast.dismiss();
  toast.success(msg);
};
export const showErr = (msg: string) => {
  toast.dismiss();
  toast.error(msg);
};

export const asyncToast = ({
  promise,
  loading,
  success,
}: {
  promise: any;
  loading: string;
  success: string;
}) => {
  toast.promise(promise, {
    loading,
    success,
    error: (error) => error.toString() || 'Internal error, try later.',
  });
};

export const imageUrl = (url: string) => {
  return url[0] === '/' ? url : `/${url}`;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const formattedNumber = phoneNumberString
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  return formattedNumber;
};

export function formatLocalDate(date: string, format?: string) {
  return moment
    .utc(date)
    .local()
    .format(format || 'Do MMM YYYY hh:mm a');
}

/**
 * Maps status with label.
 */

export const statusTitleMapper: { [key: string]: string } = {
  processing: 'Shipping Pending',
};

/**
 * This will return label related to given status.
 * If not found then status itself will be return.
 * @param status string
 * @returns status label
 */

export function getStatusTitle(status: string) {
  return statusTitleMapper[status] || status;
}

/**
 * getYupErrors will format errors to simple object
 * from yup error inner object.
 */
export const getYupErrors = (error: any) => {
  const errors = error.inner.reduce((acc: any, error: any) => {
    return {
      ...acc,
      [error.path]: error.message,
    };
  }, {});
  return errors;
};
