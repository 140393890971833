import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { apiClient } from 'helpers/http';
import PermissionController from 'modules/PermissionController';
import { useQuery } from 'react-query';
const BASE_URL = process.env.REACT_APP_BACKEND_API;
const client = axios.create({
  baseURL: BASE_URL,
});

interface AuthContextType {
  user: any;
  signin: (user: string, callback: VoidFunction) => void;
  signout: () => void;
  isLoading: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isBoostraping, setIsBootstraping] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    setIsBootstraping(true);
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    if (user && token) {
      setUser(JSON.parse(user));
      apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      setIsBootstraping(false);
    }
    setTimeout(() => {
      setIsBootstraping(false);
    }, 100);
  }, []);

  const signin = (formdata: any, callback: VoidFunction) => {
    setIsLoading(true);
    client
      .post('/auth/admin/login', formdata)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status) {
          setUser(res.data?.data?.user);
          localStorage.setItem('user', JSON.stringify(res.data?.data?.user));
          localStorage.setItem('token', res.data?.data?.token);
          apiClient.defaults.headers.common['Authorization'] =
            'Bearer ' + res.data?.data?.token;
          callback();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(
          err.response?.data?.message || 'Something went wrong, try later!'
        );
      });
  };

  const signout = React.useCallback(() => {
    setUser(null);
    navigate('/login');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }, [navigate]);

  const value = React.useMemo(
    () => ({ user, signin, signout, isLoading }),
    [isLoading, signout, user]
  );
  if (isBoostraping) {
    return null;
  }
  return (
    <AuthContext.Provider value={value}>
      <PermissionController>{children}</PermissionController>
    </AuthContext.Provider>
  );
}

function useAuth() {
  return React.useContext(AuthContext);
}

export { AuthProvider, useAuth };
