import styled from 'styled-components';
import { mediaQuery } from 'helpers/hooks/useResponsive';
export const StyledWrapper = styled.div`
  .back-text {
    font-size: 16px;
    margin-left: 5px;
    &:hover {
      color: #000000;
    }
  }
  .brand-name {
    color: #747474;
    font-size: 0.8125rem;
  }
  .add-listing-text {
    font-size: 24px;
    padding-left: 10px;
    margin-left: 15px;
  }
  .select-product-main {
    margin: 50px auto;
    width: calc(100% - 180px);
    .product-wrapper {
      width: 100%;
      .select-bullet-main {
        display: flex;
        flex-direction: column;
        .select-line {
          height: calc(100% - 855px);
        }
      }
      .product-section-wrapper {
        width: 100%;
        .product-section,
        .product-details-section {
          margin: 0px 0px 50px 20px;
          .select-product-text {
            font-size: 20px;
            font-weight: 600;
            margin: 10px 0px;
          }
          .add-listing-container {
            background: #ffffff;
            padding: 16px;
            border: 1px solid #ffffff;
            border-radius: 12px;
            @media ${mediaQuery.mobile} {
              padding: 2px;
            }
            .add-listing-header {
              .search-container {
                width: 100%;
                max-width: 100%;
              }
              gap: 16px;
              .add-listing-filter {
                background: #ffffff;
                border: 1px solid #ffffff;
                border-radius: 8px;
                padding: 13px 16px;
                gap: 12px;
                box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.06);
              }
            }
          }
        }
        .product-details-section {
          margin-bottom: 0px;
          .add-listing-container {
            padding: 28px 30px;
            .general-text {
              font-size: 18px;
              font-weight: bold;
            }
            .sneaker-section,
            .box-section {
              .sneaker-label {
                color: #292d32;
                font-size: 16px;
                margin: 40px 0px 10px;
              }
              .sneaker-btn-container {
                display: flex;
                gap: 15px;
                .sneaker-btn {
                  background: #e7e7e7;
                  border-radius: 8px;
                  border: 1px solid #e7e7e7;
                  width: 150px;
                  height: 50px;
                  color: #747474;
                  text-align: center;
                  padding: 10px;
                  cursor: pointer;
                  &.active-btn {
                    border: 2px solid #000000;
                    color: #000000;
                  }
                }
                @media ${mediaQuery.mobile} {
                  gap: 5px;
                  .sneaker-btn {
                    width: 110px;
                    display: inline-block;
                    height: auto;
                    font-size: 12px;
                    padding: 10px 0;
                  }
                }
              }
            }
            .box-section {
              margin-top: 40px;
              .sneaker-label {
                margin: 0px 0px 10px;
              }
            }
            .issue-section,
            .price-section {
              .sneaker-label {
                color: #292d32;
                font-size: 16px;
                margin: 40px 0px 10px;
              }
              .issue-textarea {
                border: 1px solid #e7e7e7;
                box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
                border-radius: 8px;
                width: 100%;
                padding: 10px 20px;
              }
            }
            .price-section {
              display: flex;
              justify-content: space-between;
              align-items: end;
              .price-input {
                border: 1px solid #e7e7e7;
                box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
                border-radius: 8px;
                padding: 16px;
              }
              .continue-btn {
                padding: 16px 32px;
                background: #000000;
                color: #ffffff;
                border-radius: 8px;
                border: 1px solid #000000;
                font-weight: 500;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    @media ${mediaQuery.mobile} {
      margin: 0;
      width: 100%;
      .product-section {
        margin: 0 !important;
      }
      .product-details-section {
        margin: 2rem 0 0 0 !important;
        /* margin-top: 1rem !important; */
      }
      .product-section-wrapper {
        margin-top: 1rem;
      }
      .price-section{
        flex-direction: column;
      }
    }
  }
`;
