import React from 'react';
import SideBar from 'components/Sidebar';
import PageWrapper from 'components/styled/PageWrapper';
import { Outlet } from 'react-router-dom';
import { useAuth } from 'helpers/contexts/auth-context';

function PageLayout() {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);
  const auth = useAuth();
  if (!auth.user) return <Outlet />;
  return (
    <PageWrapper className={`${!isSidebarOpen ? 'withMinimized' : ''}`}>
      <SideBar show={isSidebarOpen} toggle={toggleSidebar} />
      <div className={`container-f`}>
        <section className="p-3">
          <Outlet />
        </section>
      </div>
    </PageWrapper>
  );
}

export default PageLayout;
