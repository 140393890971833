import Loader from 'components/Loader';
import { apiClient } from 'helpers/http';
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useQuery } from 'react-query';
import styled from 'styled-components';

const appSettings = () => {
  return apiClient
    .get('/general/app-settings')
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

const Wrapper = styled.div`
  header {
    padding: 1rem;
  }
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .maintenance-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`;
type PermissionProp = {
  ADMIN_MAINTENANCE_MODE: string;
  APPLICATION_NAME: string;
  APPLICATION_SHIPPING_COST: number;
  BUYER_MAINTENANCE_MODE: string;
  MAINTENANCE_MODE_TEXT: string;
  SELLER_MAINTENANCE_MODE: string;
};
function PermissionController({ children }: any) {
  const {
    data: permission,
    error,
    isLoading,
  } = useQuery<PermissionProp>('app-settings', () =>
    appSettings().then((res) => {
      return res.data;
    })
  );
  if (isLoading) {
    return <Loader />;
  }
  if (permission?.ADMIN_MAINTENANCE_MODE === 'off') {
    return children;
  }
  console.log(permission);
  return (
    <Wrapper>
      <Container className="maintenance-container">
        <header>
          <img
            src="/logo-black.png"
            title="breakout la"
            alt="breakout la"
            width={100}
          />
        </header>
        <main>
          <img src="/images/maintenance.svg" />
          <h2 className="mt-4">We're currently fixing an issue.</h2>
          <p>{permission?.MAINTENANCE_MODE_TEXT}</p>
        </main>
      </Container>
    </Wrapper>
  );
}

export default PermissionController;
