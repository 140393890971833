import styled, { css } from 'styled-components';

const StyledCard = styled.div<{
  maxHeight?: string;
  noBorder?: boolean;
  bodyPadding?: string;
}>`
  background-color: white;
  border-radius: 1rem;
  .gcard-header {
    padding: 1.5rem 1.5rem 1.25rem;
    .title {
      font-size: 1.25rem;
      font-weight: 700;
      color: ${(props) => props.theme.colors.black};
      line-height: 1.5;
      span {
        font-weight: 500;
      }
    }
    /* TODO: refactor color */
    border-bottom: 1px solid #dddddd;
    ${(props) => props.noBorder && 'border-bottom: none;padding-bottom: 0;'}
  }
  .gcard-body {
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : 'auto')};
    /* overflow: ${(props) => (props.maxHeight ? 'aut' : 'auto')}; */
    ${(props) =>
      !!props.maxHeight &&
      css`
        overflow-y: auto;
      `}
    padding: ${(props) => (props.bodyPadding ? props.bodyPadding : '1rem')};
  }
`;

function GenericCard({
  children,
  header,
  maxHeight,
  bodyClassName,
  bodyPadding,
  ...rest
}: {
  children: React.ReactNode;
  header?: React.ReactNode;
  bodyClassName?: string;
  maxHeight?: string;
  bodyPadding?: string;
  [key: string]: any;
}) {
  return (
    <StyledCard maxHeight={maxHeight} bodyPadding={bodyPadding} {...rest}>
      {header && <div className="gcard-header">{header}</div>}
      <div className={`gcard-body ${bodyClassName || ''}`}>{children}</div>
    </StyledCard>
  );
}
export default GenericCard;
