import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const DropdownWrapper = styled.div`
  width: 100px;
  select {
    width: 100%;
    max-width: 320px;
    height: 50px;
    padding: 10px;
    border-color: #e7e7e7;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  .dropdown-container {
    .dropdown-label {
      color: #292d32;
      font-size: 16px;
      margin: 10px 0px;
    }
  }
`;
function Dropdown({
  label,
  option,
  placeholder,
  onChange,
  value,
  loading,
  dropdownWrapperClass,
}: any) {
  return (
    <DropdownWrapper className={dropdownWrapperClass}>
      <div className="dropdown-container">
        <div className="d-flex dropdown-label align-items-center">
          <div>{label}</div>
          {loading && <Spinner animation="border" size="sm" className="mx-2" />}
        </div>
        <select placeholder={placeholder} onChange={onChange} value={value}>
          {option &&
            option.length > 0 &&
            option.map((item: any) => {
              return (
                <option key={item.key} value={option.value}>
                  {item.name}
                </option>
              );
            })}
        </select>
      </div>
    </DropdownWrapper>
  );
}

export default Dropdown;
