import styled, { css } from 'styled-components';
import { ReactComponent as Delete } from 'assets/icons/trash.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { Button } from 'react-bootstrap';

const StyledBtn = styled.button`
  background-color: white;
  border: solid 1px #c7c7c7;
  border-radius: 0.5rem;
  width: 40px;
  height: 40px;
  transition: all 0.15s ease-in-out;
  &:hover {
    transform: scale(1.035);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  &.bg-green {
    background-color: #ebfff0;
    border: none;
  }
  &.bg-red {
    background-color: #ffeaea;
    border: none;
  }
`;

export const IconButton = ({ name, ...rest }: any) => (
  <StyledBtn {...rest}>
    {name === 'delete' && <Delete />}
    {name === 'edit' && <Edit />}
    {name === 'check' && <Check stroke="#32B155" />}
  </StyledBtn>
);

export const StyledButton = styled(Button)<{ height: number }>`
  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `};

  border-radius: 0.5rem;
  svg {
    margin-right: 4px;
  }
  &.btn-outline-primary {
    border: 1px solid #c7c7c7;
    color: ${(props) => props.theme.colors.black};
    font-weight: 400;
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: transparent;
      box-shadow: rgba(0, 0, 0, 0.1) -2px 2px 6px 0px;
      transform: translateY(-2px) rotate(2deg);
    }
  }
`;

export const TransparentButton = styled(Button).attrs(() => ({
  variant: 'transparent',
}))`
  border: none;
  color: #283eff;
  font-weight: 500;
  margin-bottom: 10px;
`;
