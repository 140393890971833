// my-theme.ts
const myTheme = {
  font: {
    primary: `'Inter', sans-serif`,
  },
  colors: {
    body: 'white',
    black: '#000',
    white: '#fff',
    whiteSmoke: '#f3f3f3',
    lightGray: '#ECECEC',
    bg: '#F5F5F5',
    darkText: '#0D0D0D',
    gray1: '#D9D9D9',
    blue1: '#283EFF',
    gray2: '#606060',
    gray3: '#e7e7e7',
    gray4: '#EEEEEE',
    gray5: '#737373',
    placeholderText: '#b7b7b7',
  },
  fontSize: {
    sm: '1.2rem',
    md: '1.4rem',
    lg: '1.6rem',
    xl: '1.8rem',
  },
  borderRadius: {
    sm: '0.2rem',
    md: '0.4rem',
    lg: '0.6rem',
    xl: '0.8rem',
  },
  container: {
    sm: '20rem',
    md: '30rem',
    lg: '40rem',
    xl: '50rem',
  },
  statusColors: {
    green: {
      bg: '#EAFFF1',
      color: '#32B155',
    },
    yellow: {
      bg: '#FFF5DD',
      color: '#E3891E',
    },
    gray: {
      bg: '#E7E7E7',
      color: '#5F5F5F',
    },
    red: {
      bg: '#FFEAEA',
      color: '#FF0505',
    },
    blue: {
      bg: '#EAF2FF',
      color: '#283EFF',
    },
    pink: {
      bg: '#FFEAFC',
      color: '#FF28A9',
    },
    violet: {
      bg: '#F9EAFF',
      color: '#C728FF',
    },
    lightBlue: {
      bg: '#EAF0FF',
      color: '#3255B1',
    },
    lightPurple: {
      bg: '#F2EAFF',
      color: '#6A32B1',
    },
  },
  // button: {
  //   primary: {
  //     outline: '#c7c7c7',
  //   },
  // },
};

export { myTheme };
