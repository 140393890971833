import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
const SearchContainer = styled.div`
  max-width: 318px;
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.04);
  padding: 0 1rem;
  border-radius: 0.5rem;
  transition: all 0.25s;
  &:hover {
    transform: scale(1.035);
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.04),
      0px 0 0 1px rgba(0, 0, 0, 0.24);
  }
  input {
    height: 52px;
    border: none;
    box-shadow: none !important;
  }
`;
function Search({
  placeholder = 'Search by name or SKU#',
  onChange,
  value,
  containerClassName,
}: {
  placeholder?: string;
  onChange?: React.ChangeEvent<HTMLInputElement> | any;
  value?: string;
  containerClassName?: string;
}) {
  if (onChange) {
    return (
      <SearchContainer className={containerClassName}>
        <SearchIcon />
        <Form.Control
          placeholder={placeholder}
          value={value || ''}
          onChange={onChange}
        />
      </SearchContainer>
    );
  }
  return (
    <SearchContainer className={containerClassName}>
      <SearchIcon />
      <Form.Control placeholder={placeholder} />
    </SearchContainer>
  );
}

export default Search;
