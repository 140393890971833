import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Routes, Route, Navigate } from 'react-router-dom';
import { MyGlobalStyle } from './styles/GlobalStyle';
import { myTheme } from './styles/theme';
import RequireAuth from 'app-router/RequireAuth';
import { AuthProvider, useAuth } from 'helpers/contexts/auth-context';
import { CssUtils } from 'styles/CssUtils';
import PageLayout from 'components/Layout/PageLayout';
import SuspenseWrapper from 'app-router/SuspenseWrapper';
import AddListing from 'pages/add-listing';
import { UtilProvider } from 'helpers/contexts/util-context';

// lazy load components
const Dashboard = React.lazy(
  /* webpackPrefetch: true */
  /* webpackChuckName: 'Dashboard' */
  () => import('pages/dashboard')
);
const DefaultProducts = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/default-products')
);
const Sellers = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/sellers')
);
const SellerDetails = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/seller-details')
);
const Buyers = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/buyers')
);
const Listing = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/listing')
);
const QrListing = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/listing/QrListing')
);
const ListingRemovalRequest = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/pull-items/ListingRemovalRequest')
);
const PullItems = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/pull-items')
);
const ListingDetails = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/listing-details')
);
const BuyerDetails = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/buyer-details')
);
const Page404 = React.lazy(
  /* webpackPrefetch: true */
  () => import('components/Page404')
);
const Login = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/login')
);
const Collection = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/collection')
);
const Orders = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/orders')
);
const OrderDetails = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/orders/OrderDetails')
);
const Transactions = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/transactions')
);
const Discounts = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/discounts')
);
const Support = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/support')
);

function App() {
  return (
    <AuthProvider>
      <UtilProvider>
        <ThemeProvider theme={myTheme}>
          <div className="App">
            <Routes>
              <Route element={<PageLayout />}>
                <Route
                  path="/login"
                  element={
                    <SuspenseWrapper>
                      <Login />
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/404"
                  element={
                    <SuspenseWrapper>
                      <Page404 />
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/products"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <DefaultProducts />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/sellers"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <Sellers />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/seller/:id"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <SellerDetails />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/buyer/:id"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <BuyerDetails />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/listing/:id"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <ListingDetails />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/listing/request/:reqId"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <ListingDetails />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/qr/:id"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <QrListing />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/pull-items"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <PullItems />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/pull-item/:id"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <ListingRemovalRequest />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/buyers"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <Buyers />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/listing"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <Listing />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/collections"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <Collection />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/orders"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <Orders />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/order/:id"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <OrderDetails />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/transactions"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <Transactions />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/discounts"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <Discounts />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/support"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <Support />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
                <Route
                  path="/add-listing"
                  element={
                    <SuspenseWrapper>
                      <RequireAuth>
                        <AddListing />
                      </RequireAuth>
                    </SuspenseWrapper>
                  }
                />
              </Route>

              <Route path="/" element={<DefaultRedirect />} />
              <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
          </div>
          <MyGlobalStyle />
          <CssUtils />
        </ThemeProvider>
      </UtilProvider>
    </AuthProvider>
  );
}

export default App;

const DefaultRedirect = () => {
  const auth = useAuth();
  return <Navigate to={auth.user ? 'dashboard' : 'login'} />;
};
