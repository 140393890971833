// /admin/dashboard-stats

import { apiClient } from './index';

export const dashboardStats = () => {
  return apiClient.get('/admin/dashboard-stats').then((res) => res.data);
};

// /admin/support-requests?page=1&limit=10

export const getSupportRequests = ({
  page = 1,
  limit = 10,
  search = '',
}: {
  page?: number;
  limit?: number;
  search?: string;
}) => {
  return apiClient
    .get(
      `/admin/support-requests?page=${page}&limit=${limit}&keyword=${search}`
    )
    .then((res) => res.data);
};
export const getPayoutList = ({
  page = 1,
  limit = 10,
  search = '',
}: {
  page?: number;
  limit?: number;
  search?: string;
}) => {
  return apiClient
    .get(`/admin/seller-payouts?page=${page}&limit=${limit}&keyword=${search}`)
    .then((res) => res.data);
};

export const generateAwsUrl = (data: {
  folder: string;
  file_name: string;
  content_type: string;
}) => {
  return apiClient.post('/general/image/upload', data).then((r) => r.data);
};

export const fakePromise = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 1000);
  });
};

export const getBrands = () => {
  return apiClient.get('/general/brands').then((res) => res.data);
};

export const addBrand = (data: any) => {
  const payload = {
    ...data,
  };
  return apiClient.post('/admin/brand', payload).then((res) => {
    if (res.data.status) {
      return res.data;
    } else {
      throw new Error(res.data.message);
    }
  });
};
