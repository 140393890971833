import React from 'react';
import PermissionController from 'modules/PermissionController';

interface UtilContextType {
  orderFilter: any;
  setOrderFilter: (data: any) => void;
  listingFilter: any;
  setListingFilter: (data: any) => void;
  pullItemsFilter: any;
  setPullItemsFilter: (data: any) => void;
  sellerSearchKeyword: string;
  setSellerSearchKeyword: (keyword: string) => void;
  buyerSearchKeyword: string;
  setBuyerSearchKeyword: (keyword: string) => void;
  resetSearchKeyword: () => void;
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const UtilContext = React.createContext<UtilContextType>(null!);

function UtilProvider({ children }: { children: React.ReactNode }) {
  const [orderFilter, setOrderFilter] = React.useState({
    search: '',
    page: 1,
    activeTab: 'all',
  });
  const [listingFilter, setListingFilter] = React.useState({
    search: '',
    page: 1,
    activeTab: 'all',
  });
  const [pullItemsFilter, setPullItemsFilter] = React.useState({
    search: '',
    page: 1,
  });
  const [sellerSearchKeyword, setSellerSearchKeyword] = React.useState('');
  const [buyerSearchKeyword, setBuyerSearchKeyword] = React.useState('');

  const resetSearchKeyword = React.useCallback(() => {
    setOrderFilter({
      search: '',
      page: 1,
      activeTab: 'all',
    });
    setListingFilter({
      search: '',
      page: 1,
      activeTab: 'all',
    });
    setPullItemsFilter({
      search: '',
      page: 1,
    });
    setSellerSearchKeyword('');
    setBuyerSearchKeyword('');
  }, []);

  const value = {
    orderFilter,
    setOrderFilter,
    listingFilter,
    setListingFilter,
    sellerSearchKeyword,
    setSellerSearchKeyword,
    buyerSearchKeyword,
    setBuyerSearchKeyword,
    resetSearchKeyword,
    pullItemsFilter,
    setPullItemsFilter,
  };

  return (
    <UtilContext.Provider value={value}>
      <PermissionController>{children}</PermissionController>
    </UtilContext.Provider>
  );
}

function useUtil() {
  return React.useContext(UtilContext);
}

export { UtilProvider, useUtil };
