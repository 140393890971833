import { createGlobalStyle } from 'styled-components';
import { myTheme } from './theme';
export const MyGlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${myTheme.font.primary};
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .btn-check:focus+.btn, .btn:focus{
    box-shadow: none;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
  .form-check-input:checked{
    background-color:#292D32;
  }
  .btn.btn-link{
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    color: #283EFF;
    &:hover{
      transform: scale(1.025); 
    }
  }
  button.btn:not(.btn-link, .btn-transparent) {
    transition: all 0.15s ease-in-out;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px;
      transform: scale(1.035); 
    }
  }
  .heading-sm{
    color: #0D0D0D;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5;
  }
  .modal-open  .App{ 
    filter: blur(2.5px);
  }
  .delete-listing-modal{
    .modal-dialog{
      min-width: 500px;
    }
    .modal-body{
      padding: 2rem;
    }
    .promptTitle{
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1.75rem;
    }
    .promptMsg{
      text-align: left!important;
    }
  }
  .ban-modal{
    .modal-dialog{
      min-width: 437px;
    }
    .modal-body{
      padding: 2rem;
    }
    .promptTitle{
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 2rem;
    }
    .promptMsg{
      text-align: center!important;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.6rem;
      margin: 0.8125rem 0rem 1.75rem 0rem !important;
    }
    .bottom-buttons {
      padding: 0rem !important;
    }
    .submit-btn {
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      font-size: 1rem;
    }
    .cancel-btn {
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      font-size: 1rem;
      background: ${myTheme.statusColors.gray.bg};
      border: none;
      &:hover {
        color: #000;
      }
    }
  }
  .pointer{
    cursor: pointer;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #CACACA;
  }

  .btn.btn-secondary{
    background-color: #E7E7E7;
    color: #000000;
    border: none;
  }
  .table-responsive{
    tbody {
      tr {
        transition: all .25s;
        &:hover {
          border-top: 0 !important;
          background-color: rgba(0,0,0, 0.04);
        }
      }
    }
  }
    /* input type number  */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .qr-modal{
    .modal-dialog{
      max-width: 650px;
    }
  }
`;
