import React, { useEffect } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import { ReactComponent as Download } from 'assets/icons/document-download.svg';
import { ReactComponent as Print } from 'assets/icons/print.svg';
import { StyledModal } from 'components/styled/StyledModal';
import { StyledButton } from 'components/forms/Buttons';
import html2canvas from 'html2canvas';
import { QRCodeView } from 'pages/listing-details/listingDetails.styled';
import JSZip from 'jszip';
import { useNavigate } from 'react-router-dom';

const PRODUCT_CONDITION_LABELS = {
  pre: 'Pre-owned',
  new: 'New',
};

function MultipleQrModal({
  show,
  toggle,
  product,
  data,
  formState,
  batchNumber,
}: {
  show: boolean;
  toggle: () => void;
  product: any;
  data: any;
  formState: any;
  batchNumber?: string;
}) {
  // const printRef = React.useRef<any>(null);
  const navigate = useNavigate();
  const handlePrint = () => {
    const a = window.open('', '');
    const qrcodeLabel = document.getElementById('qrcodeLabel');
    if (qrcodeLabel) {
      html2canvas(qrcodeLabel).then((canvas) => {
        if (canvas) {
          const codeImage = canvas.toDataURL();
          if (a) {
            a.document.write('<html>');
            a.document.write(`<img src=${codeImage}>`);
            a.document.write('</body></html>');
            a.document.close();
            a.onload = () => a.print();
          }
        }
      });
    }
  };
  const handleQrDownload = async () => {
    const zip = new JSZip();
    for (let i = 0; i < data.length; i++) {
      const qrId = data[i].id;
      const qrcodeLabel = document.getElementById(`qrcodeLabel-${qrId}`);
      if (qrcodeLabel) {
        const canvas = await html2canvas(qrcodeLabel);
        const blob = canvas.toDataURL('png');
        zip.file(`${qrId}.png`, blob.substring(blob.indexOf(',') + 1), {
          base64: true,
        });
      }
    }
    const a = document.createElement('a');
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      const reader = new FileReader();
      reader.onload = function (e) {
        a.href = e.target?.result as string;
        a.download = `${batchNumber || 'qrcode'}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      reader.readAsDataURL(content);
    });
  };
  const onClose = () => {
    navigate('/listing');
  };
  return (
    <StyledModal show={show} centered onHide={onClose} className="qr-modal">
      <Modal.Header className="p-0">
        <Button variant="transparent" className="close" onClick={onClose}>
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body className="qr-modal-content">
        <div className="modal--head mt-4">
          <h2 className="modal--title">QR Code</h2>
          {/* <p className="modal--subtitle">QR code for the Approved Listing.</p> */}
        </div>
        <div id="qrcodeLabel">
          {data.map((item) => (
            <QRCard
              key={item.id}
              item={item}
              product={product}
              formState={formState}
            />
          ))}
        </div>

        <div className="modal--foot mt-4 mb-4 d-flex g-1 justify-content-center">
          <div onClick={handleQrDownload}>
            <StyledButton variant="transparent" shadow={1}>
              <Download stroke="currentColor" /> Download
            </StyledButton>
          </div>
          <StyledButton variant="dark" shadow={1} onClick={handlePrint}>
            <Print stroke="white" /> Print
          </StyledButton>
        </div>
      </Modal.Body>
    </StyledModal>
  );
}

export default MultipleQrModal;

const QRCard = ({ item, product, formState }: any) => {
  const [qrCodeBlob, setQrCodeBlob] = React.useState<any>();
  useEffect(() => {
    (async function () {
      const qrblob = await toDataURL(item.barcodeUrl);
      setQrCodeBlob(qrblob);
    })();
  }, [item.barcodeUrl]);
  return (
    <QRCodeView
      key={item.id}
      className="modal--body d-flex align-items-start mb-4"
      id={`qrcodeLabel-${item.id}`}
    >
      {!!qrCodeBlob && (
        <Image id={`qrcode-${item.id}`} src={qrCodeBlob} width="160" />
      )}
      <div style={{ marginLeft: '1rem' }}>
        <h3 className="fs-24 fw-700">{product?.catalog_name}</h3>
        {!!product?.sku && (
          <span className="sku fs-20 fw-700 ">SKU: #{product?.sku}</span>
        )}
        {!!item?.product_item_code && (
          <div className="sku fs-20 fw-700">
            Listing ID: {item?.product_item_code}
          </div>
        )}
        <div className="mt-3 d-flex g-1 flex-wrap">
          <div className="qr-tag fw-bold fs-20">
            {
              PRODUCT_CONDITION_LABELS[
                formState?.product_conditions as keyof typeof PRODUCT_CONDITION_LABELS
              ]
            }
          </div>
          <div className="qr-tag fw-bold fs-20">{formState?.size_value}</div>
        </div>
        <img className="qr-logo" src="/logo-black.png" width={120} />
      </div>
    </QRCodeView>
  );
};

async function toDataURL(url: string) {
  return fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
}
