import { createGlobalStyle } from 'styled-components';
import { myTheme } from './theme';
export const CssUtils = createGlobalStyle<{ theme: typeof myTheme }>`
  .mb-2r{
    margin-bottom: 2rem;
  }
  .btn-nostyle{
    border: none;
  }
  .font-weight-bold{
    font-weight: 700;
  }
  .fs-1rem{
    font-size: 1rem!important;
  }
  .fs-2rem{
    font-size: 2rem;
  }
  .fs-18{
    font-size: 1.125rem;
  }
  .fs-20{
    font-size: 1.25rem;
  }
  .fs-20{
    font-size: 1.25rem;
  }
  .g-1{
    gap: 0.5rem;
  }
  .g-2{
    gap: 1rem;
  }
  .fw-700{
    font-weight: 700;
  }
  .fw-600{
    font-weight: 600;
  }
  .fw-500{
    font-weight: 500;
  }
  .fw-400{
    font-weight: 400;
  }
  .fs-14{
    font-size: .875rem;
  }
  .reset-hover-color{
    &:hover{
      color: inherit;
    }
  }
  .color-green{
    color: ${myTheme.statusColors.green.color};
  }
  .flex-1 {
    flex: 1;
  }
  @media screen and (max-width: 992px){
    .md-screen-flex-wrap {
      flex-wrap: wrap;
    }
  }
`;
