/* eslint-disable */
import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import Search from 'components/forms/Search';
import Dropdown from 'components/forms/Dropdown';
import TableComponent from './TableComponent';
import Loader from 'components/Loader';
import ErrorMessage from 'components/ui/ErrorMessage';
import { getDefaultListing, getProductConfig } from 'helpers/http/product';
import { apiClient } from 'helpers/http';
import { getYupErrors, showErr, showMsg } from 'helpers/utils/misc';
import useDebounce from 'helpers/hooks/useDebounce';
import FilterPopup from './FilterPopup';
import { validateListingSchema } from 'helpers/validation/listingValidation';
// import { useDraft } from 'helpers/contexts/draft-context';
// import { ReactComponent as BackIcon } from 'assets/icons/backImage.svg';
import { ReactComponent as BulletIcon1 } from 'assets/icons/bulletIcon1.svg';
import { ReactComponent as BulletIcon2 } from 'assets/icons/bulletIcon2.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import { ReactComponent as SelectLine } from 'assets/icons/selectLine.svg';
import { StyledWrapper } from './addlisting.styled';
import { isSneaker } from './listing.helper';
import { ProductType } from 'pages/default-products/product.type';
import { Spinner } from 'react-bootstrap';
import { StyledButton } from 'components/forms/Buttons';
import { useQueryClient } from 'react-query';
import { MultipleProductCount } from 'pages/dashboard/dashboard.styled';
import MultipleQrModal from './MutipleQrModal';
import sampleResponse from './sampleResponse';

const CONDITION_LABELS = {
  good: 'Good Condition',
  missing: 'Missing Lid',
  damaged: 'Damaged',
  originalBox: 'No Original Box',
};

const defaultFilters = {
  categoryId: [],
  brandId: [],
  gender: ['men', 'women', 'kid'],
};

export type Listing = {
  brand_id: number;
  brand_name: string;
  catalog_images: string;
  catalog_name: string;
  category_id: number;
  category_name: string;
  default_price: string;
  gender: string;
  product_catalog_id: string;
  product_catalog?: ProductType;
  sku: string;
};
type DefaultState = {
  product_catalog_id: string;
  product_catalog?: ProductType | null;
  size_value: number | string;
  sell_price: number | string;
  product_conditions: string;
  box_condition?: string;
  other_issue?: string;
  quantity: number;
};
const defaultState: DefaultState = {
  product_catalog_id: '',
  product_catalog: null,
  size_value: '',
  sell_price: '',
  product_conditions: 'new',
  box_condition: '',
  other_issue: '',
  quantity: 1,
};
interface LocationState {
  selectedProduct: any;
}

function AddListing() {
  const queryClient = useQueryClient();
  const [qrModal, setQrModal] = useState<any>({
    show: false,
    data: [],
  });
  const toggleQrModal = () => setQrModal({ ...qrModal, show: !qrModal.show });
  const { state } = useLocation();
  const [listing, setListing] = React.useState<Listing[] | null>(null);
  const [searchKeyword, setSearchKeyword] = React.useState(undefined);
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);
  const [isFetching, setIsFetching] = React.useState(false);
  const [filterDialog, setFilterDialog] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState(defaultFilters);
  const [errors, setErrors] = React.useState<any>({});
  const [fetchingConfig, setFetchingConfig] = React.useState<boolean>(false);
  const [sizeOptions, setSizeOptions] = React.useState<any>([]);
  const [creatingListing, setCreatingListing] = React.useState<boolean>(false);

  const fetchListing = React.useCallback(
    (search?: string, filterParams: any = filters) => {
      const appSettings =
        queryClient.getQueryData<{ BREAKOUT_BRAND_ID: number }>('app-settings');
      setIsFetching(true);
      getDefaultListing(search || '', {
        ...filterParams,
        brandId: [appSettings?.BREAKOUT_BRAND_ID],
      }).then((res) => {
        setIsFetching(false);
        setListing(res.data);
        if (state !== null) {
          const { selectedProduct } = state as LocationState;
          if (selectedProduct) {
            updateFormState({
              product_catalog: selectedProduct,
              product_catalog_id: selectedProduct.product_catalog_id,
            });
          }
        }
      });
    },
    []
  );

  React.useEffect(() => {
    fetchListing(debouncedSearchKeyword);
  }, [debouncedSearchKeyword, fetchListing]);

  React.useEffect(fetchListing, [fetchListing]);

  function handleSearch(e: any) {
    setSearchKeyword(e.target.value);
  }

  const handleSelect = (selectedItem: any) => () => {
    updateFormState({
      product_catalog: selectedItem,
      product_catalog_id: selectedItem.product_catalog_id,
      box_condition: isSneaker(selectedItem?.category_name || '') ? 'good' : '',
    });
    fetchProductSizeOptions(selectedItem._category_id);
  };

  const fetchProductSizeOptions = (category_id: number) => {
    setFetchingConfig(true);
    getProductConfig(category_id).then((res) => {
      if (res.status) {
        let formattedOptions = res?.data?.sizes.map((item: any) => {
          return {
            key: item,
            name: item,
            value: item,
          };
        });
        if (formattedOptions.length !== 1) {
          formattedOptions.unshift({
            key: '0',
            name: 'Select size',
            value: '',
          });
        }
        updateFormState({ size_value: formattedOptions[0].value });
        setSizeOptions(formattedOptions);
        setFetchingConfig(false);
      }
    });
  };

  const onEditProduct = () => {
    updateFormState({
      product_catalog: null,
      product_catalog_id: '',
    });
  };

  const [formState, setFormState] = useState<DefaultState>(defaultState);
  const updateFormState = (update: any) => {
    setFormState((prevState) => ({
      ...prevState,
      ...update,
    }));
  };
  const navigate = useNavigate();

  /**
   * This will validate listing form data with yup.
   * If data is valid then it will save to backend
   * otherwise it will show errors.
   */
  const submitForm = async () => {
    setCreatingListing(true);
    validateListingSchema.isValid(formState).then((valid) => {
      if (!valid) {
        setCreatingListing(true);
        validateListingSchema
          .validate(formState, { abortEarly: false })
          .catch((err) => {
            const errors = getYupErrors(err);
            setErrors({ ...errors });
          });
      } else {
        setErrors({});
        setCreatingListing(true);
        const promise = apiClient
          .post('/admin/seller-listing/manage', {
            ...formState,
            action: 'add_listing',
          })
          .then((res) => {
            if (!res.data?.status) {
              throw new Error(res.data?.message);
            }
            return res;
          });
        toast.promise(promise, {
          loading: 'Processing...',
          success: (res) => {
            setQrModal({
              show: true,
              data: res.data.userProductIds,
              batchNumber: res.data.batchNumber,
            });
            setCreatingListing(false);
            return res.data.message;
          },
          error: (err) => {
            setCreatingListing(false);
            return err?.response?.data?.message || err.toString();
          },
        });
      }
    });
  };

  const toggleFilterDialog = React.useCallback(() => {
    setFilterDialog((dialog: boolean) => !dialog);
  }, []);

  const handleFilter = (filters: any) => {
    toggleFilterDialog();
    // setPage(1);
    setFilters(filters);
    fetchListing('', filters);
  };
  const { isMobile } = { isMobile: false };
  return (
    <StyledWrapper>
      <div className="d-flex align-items-center">
        <div className="add-listing-text fs-24 fw-700">Add New Listing</div>
      </div>

      <div className="select-product-main">
        <div className="d-flex product-wrapper">
          {!isMobile && (
            <div className="select-bullet-main align-items-center">
              <BulletIcon1 />
              <SelectLine className="select-line" />
              <BulletIcon2 />
            </div>
          )}

          <div className="product-section-wrapper">
            <div className="product-section">
              <div className="select-product-text">Select product</div>
              <div className="add-listing-container">
                {formState.product_catalog === null ? (
                  <div className="add-listing-header d-flex align-items-center">
                    <Search
                      containerClassName="search-container"
                      placeholder="Search by name, SKU, type"
                      onChange={handleSearch}
                      value={searchKeyword}
                      // fetching={isFetching}
                    />
                  </div>
                ) : null}
                {listing === null || (isFetching && <Loader />)}
                {console.log('listing', listing)}
                {!isFetching && (
                  <>
                    {!!listing?.length ? (
                      formState.product_catalog === null ? (
                        <TableComponent
                          data={listing}
                          onSelect={handleSelect}
                          selected={formState.product_catalog_id}
                        />
                      ) : (
                        <TableComponent
                          data={[formState.product_catalog]}
                          onSelect={handleSelect}
                          selected={formState.product_catalog_id}
                          preview={true}
                          onEdit={onEditProduct}
                        />
                      )
                    ) : null}
                    {!!listing && listing.length === 0 ? (
                      <div className="mt-3 fs-14">
                        Can't find your item? No worries, just add the ones you
                        can find and we will handle the rest when you drop it
                        off in person. We're working on adding more items daily.
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              <ErrorMessage message={errors.product_catalog_id} />
            </div>

            <div className="product-details-section">
              <div className="select-product-text">
                {isMobile ? <BulletIcon2 className="me-2" /> : null}
                Fill the details
              </div>
              <div className="add-listing-container">
                <div className="general-text">General</div>
                <div className="d-flex">
                  <Dropdown
                    option={sizeOptions}
                    label="Size"
                    placeholder="Choose Size"
                    value={formState.size_value}
                    onChange={(e: any) =>
                      updateFormState({ size_value: e.target.value })
                    }
                    loading={fetchingConfig}
                  />
                </div>
                <ErrorMessage message={errors.size_value} />
                <div className="sneaker-section">
                  <div className="sneaker-label">Product Condition</div>
                  <div className="sneaker-btn-container">
                    <div
                      className={
                        formState.product_conditions === 'new'
                          ? 'sneaker-btn active-btn'
                          : 'sneaker-btn'
                      }
                      onClick={() =>
                        updateFormState({ product_conditions: 'new' })
                      }
                    >
                      New
                    </div>
                    <div
                      className={
                        formState.product_conditions === 'pre'
                          ? 'sneaker-btn active-btn'
                          : 'sneaker-btn'
                      }
                      onClick={() =>
                        updateFormState({ product_conditions: 'pre' })
                      }
                    >
                      Pre-owned
                    </div>
                  </div>
                </div>

                {isSneaker(formState?.product_catalog?.category_name || '') && (
                  <div className="box-section">
                    <div className="sneaker-label">Box Condition</div>
                    <div className="sneaker-btn-container flex-wrap">
                      {['good', 'missing', 'damaged', 'originalBox'].map(
                        (item) => (
                          <div
                            className={
                              formState.box_condition === item
                                ? 'sneaker-btn active-btn'
                                : 'sneaker-btn'
                            }
                            onClick={() =>
                              updateFormState({ box_condition: item })
                            }
                          >
                            {
                              CONDITION_LABELS[
                                item as keyof typeof CONDITION_LABELS
                              ]
                            }
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

                <div className="issue-section">
                  <div className="sneaker-label">Any Other Issues?</div>
                  <textarea
                    rows={4}
                    placeholder="Describe if there’s any other issue"
                    className="issue-textarea"
                    onChange={(e) =>
                      updateFormState({ other_issue: e.target.value })
                    }
                  />
                </div>
                {/* todo: @amit pls update these to generic input, not a good practice to have each input box designed saperatly */}
                <div className="price-section">
                  <div>
                    <div className="sneaker-label">Quantity</div>
                    <input
                      type="number"
                      placeholder="Enter Quantity"
                      className="price-input"
                      min="1"
                      value={formState.quantity}
                      onChange={(e) =>
                        updateFormState({ quantity: e.target.value })
                      }
                    />
                    <ErrorMessage
                      className="d-block mt-2"
                      message={errors.quantity}
                    />
                  </div>
                </div>
                <div className="price-section">
                  <div>
                    <div className="sneaker-label">Price</div>
                    <input
                      type="number"
                      placeholder="Enter Price"
                      className="price-input"
                      min="25"
                      value={formState.sell_price}
                      onChange={(e) =>
                        updateFormState({ sell_price: e.target.value })
                      }
                    />
                    <ErrorMessage
                      className="d-block mt-2"
                      message={errors.sell_price}
                    />
                  </div>

                  <StyledButton
                    className="continue-btn"
                    onClick={submitForm}
                    disabled={creatingListing}
                  >
                    <div className="d-flex g-1 align-items-center">
                      Continue{' '}
                      {creatingListing && (
                        <Spinner size="sm" animation="grow" />
                      )}
                    </div>
                  </StyledButton>
                  {/* remove it after only for debugging */}
                  {/* <button
                    onClick={() => {
                      setQrModal({
                        show: true,
                        data: sampleResponse.userProductIds,
                      });
                    }}
                  >
                    Show QR MODEL
                  </button> */}
                  {/* end = remove it after only for debugging */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FilterPopup
        show={filterDialog}
        onClose={toggleFilterDialog}
        onFilter={handleFilter}
      />
      {qrModal.show && (
        <MultipleQrModal
          show={qrModal.show}
          toggle={toggleQrModal}
          data={qrModal.data}
          product={formState.product_catalog}
          formState={formState}
          batchNumber={qrModal.batchNumber}
        />
      )}
    </StyledWrapper>
  );
}

export default AddListing;
