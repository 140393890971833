import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled(Spinner)<{small?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ small }) => !small && 'min-height: 50vh;'}
  .spinner-border {
    font-size: 0.75rem;
    width: 60px;
    height: 60px;
  }
`;

function Loader({small}: {small?: boolean}) {
  return (
    <Wrapper small={small}>
      <Spinner animation="border" />
    </Wrapper>
  );
}



export default Loader;
