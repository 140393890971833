import * as yup from 'yup';

export function validateListingForm(payload: any): any {
  const errors: any = {};
  if (!payload.price) {
    errors.price = 'Price is required.';
  } else if (payload.price <= 0) {
    errors.price = 'Price must be grater than 0.';
  }
  if (!payload.size) {
    errors.size = 'Size is required.';
  } else if (Number(payload.size) <= 0) {
    errors.size = 'Size must be grater than 0.';
  }
  // if(!payload?.product_conditions?.box_condition) {
  //     errors.box_condition = "Box condition is required.";
  // }
  if (!payload?.product_conditions?.product_conditions) {
    errors.product_conditions = 'Product condition is required.';
  }
  return errors;
}

/**
 * Yup schema to validate listing details form
 */
export const validateListingSchema = yup
  .object({
    product_catalog_id: yup.string().required('Please select a product'),
    size_value: yup.string().required('Please select a size'),
    sell_price: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Please enter a price')
      .min(30, 'Minimum price should be $30.'),
    quantity: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Please enter a quantity')
      .min(1, 'Minimum quantity should be atleast 1.')
      .max(100, 'Maximum quantity should be 100.'),
  })
  .required();
