import { getImageUrl } from 'helpers/utils/misc';
import { Image, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { Listing } from './AddListing';
import { ReactComponent as Edit } from 'assets/icons/edit-blue.svg';

const Wrapper = styled.div<{ isSelected?: boolean }>`
  background-color: white;
  padding: ${({ isSelected }) => (isSelected ? '0rem' : '1rem')};
  border-radius: 6px;
  margin-top: ${({ isSelected }) => (isSelected ? '0rem' : '1.635rem')};
  max-height: 400px;
  overflow-y: auto;
`;
const TableWrap = styled(Table)<{ isSelected?: boolean }>`
  ${({ isSelected }) => isSelected && 'margin-bottom: 0px;'}
  tr {
    cursor: pointer;
    &:hover,
    &.active {
      background-color: rgba(0, 0, 0, 0.025);
    }
  }
  thead {
    th {
      background-color: ${(props) => props.theme.colors.whiteSmoke};
      padding: 0.8rem;
      border: 0;
      color: black;
      font-weight: 400;
      text-transform: capitalize;
      font-size: 1rem;
    }
    th:first-child {
      border-radius: 6px 0 0 6px;
    }
    th:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
  tbody {
    border-top: 0 !important;
  }
  td {
    font-size: 0.875rem;
    border-bottom: 0;
    padding: 0.8rem;
  }
`;
const Product = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    object-fit: contain;
    margin-right: 10px;
  }
`;

const TableComponent = ({
  data: listings,
  onSelect,
  selected,
  preview = false,
  onEdit,
}: any) => {
  return (
    <Wrapper isSelected={Boolean(onEdit)}>
      <TableWrap responsive isSelected={Boolean(onEdit)}>
        {!preview && (
          <thead>
            <tr>
              <th>Product</th>
              <th>SKU#</th>
              <th>Type</th>
            </tr>
          </thead>
        )}
        <tbody>
          {listings?.length > 0
            ? listings.map((listing: Listing) => (
                <tr
                  className={`align-middle ${
                    selected === listing.product_catalog_id
                      ? onEdit
                        ? 'selected-active'
                        : 'active'
                      : ''
                  }`}
                  key={listing.product_catalog_id}
                  onClick={!preview && onSelect(listing)}
                >
                  <td>
                    <Product>
                      <Image
                        src={getImageUrl(listing.catalog_images , 'single') as string}
                      />
                      <div>
                        <div className="brand-name fs-14 fw-400">
                          {listing.brand_name}{' '}
                        </div>
                        <span className="fs-14 fw-400">
                          {listing.catalog_name}{' '}
                        </span>
                      </div>
                    </Product>
                  </td>
                  <td>{listing.sku}</td>
                  <td>{listing.category_name}</td>
                  {preview && (
                    <td>
                      <Edit className="cursor-pointer" onClick={onEdit} />
                    </td>
                  )}
                </tr>
              ))
            : null}
        </tbody>
      </TableWrap>
    </Wrapper>
  );
};

export default TableComponent;
